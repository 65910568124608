import React from 'react';
import ProjectTemplate from './ProjectTemplate';

function Projects(props) {
    return (
        <div >
            <h2>My Projects</h2>
            <ProjectTemplate/>
        </div>
    );
}

export default Projects;